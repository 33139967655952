/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$white: #fff;
$black: #0a0a0a;
$greyAD: #ADADAD;
$greyE0: #E0E0E0;
$greyE7: #E7E7E7;
$greyF6: #F6F6F6;
$greyD8: #D8D8D8;
$greyB8: #B3B3B3;
$greyC9: #c9c9c9;
$greyB9: #b9b9b9;
$red: #FF0000;
$default-primary-base-color: #f26323;
$default-primary-dark-color: #d1490c;
$default-primary-light-color: #f69e77;
$default-secondary-base-color: #eee;
$default-secondary-dark-color: #929292;
$default-secondary-light-color: #f8f8f8;
$font-muli: 'Muli', sans-serif;
$font-standard-size: 62.5%;
$dinCond: "DINCond";
$futuraMedium: "FuturaMedium";
$futuraBook: "FuturaBook";
$futuraDemi: "FuturaDemi";
$futuraLight: "FuturaLight";

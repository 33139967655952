/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --wishlist-heart-size: 12px;
    --wishlist-heart-background: var(--primary-base-color);
}

.ProductWishlistButton {
    --loader-scale: .6;

    // margin-left: 1.08rem;
    vertical-align: middle;
    display: inline-block;
    margin-top: 15px;
    width: 100%;

    // @include mobile {
    //     margin-left: 1.26rem;
    // }

    @include tablet-portrait {
        margin-top: 1.2rem;
    }

    @include mobile {
        margin: 0 0 -10px 0;
    }

    &-Button {
        background-color: $black;
        color: $white;
        font-family: $futuraDemi;
        font-size: 15px;
        text-transform: uppercase;
        padding: 7px 20px;
        border: none;
        width: 100%;
        letter-spacing: 0.75px;
        border: 1px solid $black;
        font-weight: inherit;

        @include mobile {
            padding: 18px 20px;
        }

        // --button-padding: 0;
        // --button-hover-background: transparent;
        // --button-hover-border: var(--primary-dark-color);

        // height: 47px;
        // width: 47px;

        // &_isInWishlist {
        //     --wishlist-heart-background: #fff;
        //     --button-hover-background: var(--primary-dark-color);
        //     --button-hover-border: var(--primary-dark-color);

        //     &:hover {
        //         @include after-mobile {
        //             --wishlist-heart-background: #fff;
        //         }
        //     }
        // }

        // &.Button {
        //     border-radius: 50%;
        // }

        .icon-favoris-empty,
        .icon-favoris-fill,
        .icon-favoris {
            display: none;
        }
    }

    &:hover {
        // @include after-mobile {
        //     --wishlist-heart-background: var(--primary-dark-color);
        // }
        background-color: $white !important;
        color: $black !important;
    }

    &-Heart {
        transform: rotate(-45deg);
        bottom: 0;
        top: 1px;
        margin: auto;

        &,
        &::after,
        &::before {
            height: var(--wishlist-heart-size);
            width: var(--wishlist-heart-size);
            background-color: var(--wishlist-heart-background);
        }

        &::after,
        &::before {
            content: '';
            border-radius: 50%;
            position: absolute;
        }

        &::before {
            top: calc(var(--wishlist-heart-size) / -2);
            right: 0;
        }

        &::after {
            right: calc(var(--wishlist-heart-size) / -2);
            top: 0;
        }
    }
}

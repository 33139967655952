/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ShareWishlistPopup {
    .Button {
        align-self: center;
        background-color: $black;
        color: $white;
        font-family: $futuraDemi;
        font-size: 15px;
        text-transform: uppercase;
        padding: 7px 20px;
        border: none;
        width: auto;
        letter-spacing: 0.75px;
        border: 2px solid $black;
        text-align: center;
        text-decoration: none;
        transition: background-color 0.3s ease, color 0.3s ease;
        font-weight: initial;

        &:hover {
            background-color: $white;
            color: $black;
        }
    }

    &_isSubmitSuccess {
        .ShareWishlistPopup-Success {
            opacity: 1;
            pointer-events: initial;
        }
    }

    &-Success {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $white;
        opacity: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        font-family: $futuraLight;
        font-size: 1.8rem;
        font-weight: inherit;
        text-transform: uppercase;
        will-change: opacity;
        transition: opacity ease-out .5s;

        .Button {
            margin-top: 30px;
        }
    }

    textarea {
        width: 100%;
    }

    &-Info {
        margin-top: 2.5rem;
        margin-bottom: 0;
        font-family: $futuraDemi;
        font-size: 1.4rem;
        font-weight: inherit;
        color: $black;
        text-align: left;
        text-transform: uppercase;
    }

    .Popup-Content {
        border-radius: 0;
        overflow: hidden;

        .Popup-Heading {
            width: 100%;
            text-align: center;
            font-family: $futuraLight;
            font-size: 2.0rem;
            font-weight: inherit;
            text-transform: uppercase;
        }

        .Field-Label {
            font-family: $futuraLight;
            font-size: 1.6rem;
            font-weight: inherit;
        }

        .FieldForm {
            display: flex;
            align-items: stretch;
            justify-content: center;
            flex-direction: column;

            .Field {
                margin-top: 2rem;
            }

            input,
            .Field_isValid input,
            textarea,
            .Field_isValid textarea {
                font-family: $futuraLight, sans-serif;
                caret-color: $black;
            }

            @include mobile {
                .Field-Message {
                    text-transform: none;
                    top: 60px;
                }
            }

        }
    }

    .Field-Message {
        top: -7px;
        text-transform: inherit;
        
        @include mobile {
           text-transform: none;
           margin-top: 0.7rem;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.FieldForm {
    &-Container {
        &_useTwoColumns {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            @include mobile {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }
        }
    }

    label {
        @include after-mobile {
            min-width: calc(100% - 30px);
        }
    }

    input {
        width: 100%;

        &[type='checkbox'],
        &[type='radio'] {
            width: 0;
        }

        + label {
            min-width: auto;
        }
    }

    &-Fields {
        margin-bottom: 1.2rem;

        @include mobile {
            margin-bottom: 1.4rem;
        }

        &_useTwoColumns {
            width: calc(50% - 1.2rem);

            @include mobile {
                width: 100%;

                &:first-child {
                    margin-bottom: 0;
                }
            }
        }

        &_isActions {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            @include mobile {
                flex-direction: column;
            }       
        }
    }

    &-ErrorMessage {
        margin-top: 1.6rem;
        margin-left: 1.5rem;
        font-size: 1.6rem;
        font-family: $futuraLight;

        &_isSuccess {
            color: black;
        }

        &_isError {
            color: var(--primary-error-color);
        }

        @include mobile {
            margin: 1rem 0;
        }
    }
}

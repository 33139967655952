// icomoon -----------------
@font-face {
    font-family: 'icomoon';
    src:  url('/style/fonts/icomoon.eot?11g9n3');
    src:  url('/style/fonts/icomoon.eot?11g9n3#iefix') format('embedded-opentype'),
    url('/style/fonts/icomoon.ttf?11g9n3') format('truetype'),
    url('/style/fonts/icomoon.woff?11g9n3') format('woff'),
    url('/style/fonts/icomoon.svg?11g9n3#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-play:before {
  content: "\e91f";
}
.icon-password-visible:before {
  content: "\e91d";
}
.icon-password-hidden:before {
  content: "\e91e";
}
.icon-share:before {
  content: "\e91c";
}
.icon-circle-alert:before {
  content: "\e919";
}
.icon-circle-check:before {
  content: "\e91a";
}
.icon-circle-info:before {
  content: "\e91b";
}
.icon-download:before {
  content: "\e917";
  color: #fff;
}
.icon-eye:before {
  content: "\e918";
}
.icon-link:before {
  content: "\e916";
}
.icon-swiper-arrow-left:before {
  content: "\e914";
}
.icon-swiper-arrow-right:before {
  content: "\e915";
}
.icon-book:before {
  content: "\e910";
  color: #fff;
}
.icon-collection:before {
  content: "\e911";
  color: #fff;
}
.icon-home:before {
  content: "\e912";
  color: #fff;
}
.icon-warranty:before {
  content: "\e913";
  color: #fff;
}
.icon-iconic:before {
  content: "\e90f";
}
.icon-help:before {
  content: "\e90e";
}
.icon-favoris-empty:before {
  content: "\e90d";
}
.icon-filters:before {
  content: "\e90b";
}
.icon-favoris-fill:before {
  content: "\e90c";
}
.icon-circle:before {
  content: "\e90a";
}
.icon-diamant:before {
  content: "\e908";
}
.icon-star:before {
  content: "\e909";
}
.icon-search:before {
  content: "\e900";
}
.icon-favoris:before {
  content: "\e901";
}
.icon-account:before {
  content: "\e902";
}
.icon-cheveron-up:before {
  content: "\e904";
}
.icon-cheveron-right:before {
  content: "\e905";
}
.icon-cheveron-left:before {
  content: "\e906";
}
.icon-cheveron-down:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e903";
}

// --------------------------


@font-face {
    font-family: 'DINCond';
    src:  url('/style/fonts/DINCond/DINCond.eot?11g9n3');
    src:  url('/style/fonts/DINCond/DINCond.eot?11g9n3#iefix') format('embedded-opentype'),
    url('/style/fonts/DINCond/DINCond.ttf?11g9n3') format('truetype'),
    url('/style/fonts/DINCond/DINCond.woff?11g9n3') format('woff'),
    url('/style/fonts/DINCond/DINCond.svg?11g9n3') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'FuturaBook';
    src:  url('/style/fonts/FuturaBook/FuturaBook.eot?11g9n3');
    src:  url('/style/fonts/FuturaBook/FuturaBook.eot?11g9n3#iefix') format('embedded-opentype'),
    url('/style/fonts/FuturaBook/FuturaBook.ttf?11g9n3') format('truetype'),
    url('/style/fonts/FuturaBook/FuturaBook.woff?11g9n3') format('woff'),
    url('/style/fonts/FuturaBook/FuturaBook.svg?11g9n3') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'FuturaDemi';
    src:  url('/style/fonts/FuturaDemi/FuturaDemi.eot?11g9n3');
    src:  url('/style/fonts/FuturaDemi/FuturaDemi.eot?11g9n3#iefix') format('embedded-opentype'),
    url('/style/fonts/FuturaDemi/FuturaDemi.ttf?11g9n3') format('truetype'),
    url('/style/fonts/FuturaDemi/FuturaDemi.woff?11g9n3') format('woff'),
    url('/style/fonts/FuturaDemi/FuturaDemi.svg?11g9n3') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'FuturaLight';
    src:  url('/style/fonts/FuturaLight/FuturaLight.eot?11g9n3');
    src:  url('/style/fonts/FuturaLight/FuturaLight.eot?11g9n3#iefix') format('embedded-opentype'),
    url('/style/fonts/FuturaLight/FuturaLight.ttf?11g9n3') format('truetype'),
    url('/style/fonts/FuturaLight/FuturaLight.woff?11g9n3') format('woff'),
    url('/style/fonts/FuturaLight/FuturaLight.svg?11g9n3') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'FuturaMedium';
    src:  url('/style/fonts/FuturaMedium/FuturaMedium.eot?11g9n3');
    src:  url('/style/fonts/FuturaMedium/FuturaMedium.eot?11g9n3#iefix') format('embedded-opentype'),
    url('/style/fonts/FuturaMedium/FuturaMedium.ttf?11g9n3') format('truetype'),
    url('/style/fonts/FuturaMedium/FuturaMedium.woff?11g9n3') format('woff'),
    url('/style/fonts/FuturaMedium/FuturaMedium.svg?11g9n3') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-card-text-border-color: #979797;
    --product-card-picture-label-background: rgba(255, 255, 255, .7);
    --product-card-reviews-background: rgba(243, 243, 243, .55);
    --product-card-wishlist-button-background: rgba(243, 243, 243, .78);
    --product-card-wishlist-button-color: #d8d5d5;
    --product-card-compare-button-background: var(--product-card-wishlist-button-background);
    --product-card-compare-button-color: var(--product-card-wishlist-button-color);
    --product-card-name-line-height: 1.2em;
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 1.2em;
    --product-card-color-size: 15px;
}

@keyframes rotateHeartLoading {
    from {
        transform: scaleX(1);
    }

    50% {
        transform: scaleX(0);
    }

    to {
        transform: scaleX(1);
    }
}

.ProductCard {
    // z-index: 0;
    padding-left: 0;
    min-width: 0;
    display: flex;
    align-items: stretch;

    &::before {
        content: none;
    }

    &:last-child {
        margin-bottom: .72rem;

        @include mobile {
            margin-bottom: .84rem;
        }
    }

    &-Wrapper {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        // @include mobile {
        //     align-items: flex-end;
        // }

        .ProductCard-Link {
            width: 55%;
        }

        .ProductCard-PriceWrapper {
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    &-Content {
        padding: 10px 0 15px;
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        @include mobile {
            padding-top: 4px;
        }

        &_layout_list {
            justify-content: space-between;
            margin-left: 20px;
            width: 100%;

            .ProductCard {
                &-ActionWrapper {
                    align-items: center;
                    display: flex;

                    button {
                        margin-right: 10px;
                    }
                }

                &-ProductActions {
                    flex-direction: row;
                    position: static;

                    > div {
                        margin-bottom: 0;
                    }

                    .ProductCompareButton-Button {
                        height: 42px;
                        width: 42px;
                    }
                }

                &-PriceWrapper {
                    flex-grow: unset;
                }

                &-WishListButton {
                    height: 42px;
                    width: 42px;
                }
            }
        }
    }

    &-Name,
    &-Brand {
        width: 100%;
        max-width: 100%;
    }

    &-Name {
        margin: 5px 0;
        overflow: hidden;
        //white-space: nowrap;
        //text-overflow: ellipsis;
        color: $black;
        font-family: $futuraDemi;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;

        @include mobile {
            font-size: 12px;
            line-height: 13px;
            margin: 0;
        }
    }

    &-Brand {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-height: var(--product-card-brand-line-height);
        line-height: var(--product-card-brand-line-height);
        font-family: $futuraLight;
        font-size: 12px;

        @include after-mobile {
            font-size: 1.2rem;
        }
    }

    &-Types {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;

        @include mobile {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        [class^="icon-"],
        [class*=" icon-"] {
            margin-right: .6rem;
        }

        .icon-star {
            font-size: 1.2rem;
        }

        .icon-circle {
            font-size: .4rem;

            @include mobile {
                margin-right: 0.4rem;
                top: -2px;
            }
        }

        @include mobile {
            .icon-star,
            .icon-iconic {
                font-size: .8rem;
            }
        }
    }

    &-Type {
        font-family: $futuraBook;
        font-size: 12px;
        line-height: 14px;
        color: $black;
        text-transform: uppercase;
        margin-right: .6rem;

        @include mobile {
            // display: block;
            font-size: 10px;
            font-family: $futuraLight;
            margin-bottom: 5px;
        }
    }

    &-BrandAttributeValue {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    &-Picture {
        padding-bottom: 100%;

        .Image-Image {
            object-fit: cover;
        }
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        bottom: 0;
        margin: auto;
        top: 0;
    }

    &-FigureReview {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &-Reviews {
        --product-rating-size: 1.6rem;
        --product-rating-background: #ffff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 105px;
        height: 25px;
        position: absolute;
        background-color: var(--product-card-reviews-background);
        border-radius: 1rem;
        letter-spacing: normal;

        &_layout_grid {
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
        }

        @include mobile {
            --product-rating-size: 1.4rem;

            width: 100%;
            border-radius: 0;
        }
    }

    &-ConfigurableOptions {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        min-height: calc(var(--product-card-color-size) + 5px);
    }

    &-Color,
    &-Image {
        display: inline-block;
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin: 5px 7px 0 0;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-String {
        border: 1px solid var(--product-card-text-border-color);
        color: var(--product-card-text-border-color);
        display: inline-block;
        text-align: center;
        padding: 0 6px;
        margin: 5px 7px 0 0;
        line-height: 15px;
        min-width: 20px;
    }

    &-PriceWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;
    }

    &-Price {
        margin-top: 3px;
        text-align: right;
        width: 100%;
        font-size: 24px;
        color: $black;
        font-family: $dinCond;
        line-height: 28px;
        font-weight: inherit;

        @include mobile {
            margin-top: 0;
            font-size: 15px;
            line-height: 17.58px;
            letter-spacing: 0.21px;
        }
    }

    &-PriceBadge {
        color: var(--secondary-dark-color);
        font-size: 1rem;
        margin-bottom: 0;
    }

    &-Figure {
        flex-grow: 1;
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &-ProductActions {
        position: absolute;
        top: 16px;
        right: 16px;
        display: flex;
        flex-direction: column;

        @include mobile {
            top: 12px;
            right: 12px;
        }

        .ProductWishlistButton {
            margin-top: 0;

            &:hover {
                background-color: transparent !important;
            }

            &-Button {
                padding: 0;
                &:not([disabled]):hover,
                &:not([disabled]):focus {
                    background-color: transparent;
                }
            }
        }

        .icon-favoris-empty,
        .icon-favoris-fill,
        .icon-favoris {
            display: block;

            @include mobile {
                font-size: 14px;
            }
        }
        .label-favoris {
            display: none;
        }
    }

    &-WishListButton {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        transition: all .2s;
        opacity: 1;
        transform-origin: center;

        &.ProductWishlistButton-Button_isLoading {
            animation: rotateHeartLoading 0.7s infinite ease-in-out;
        }

        &.ProductWishlistButton-Button_isInWishlist {
            opacity: 1;

            &:hover {
                @include after-mobile {
                    opacity: .5;
                }
            }
        }

        .icon-favoris-empty,
        .icon-favoris-fill,
        .icon-favoris {
            color: $black;
            font-size: 18px;
        }

        //.ProductWishlistButton-Heart {
        //    --wishlist-heart-size: 15px;
        //
        //    transform: scale(.7) rotate(-45deg);
        //    background-color: var(--product-card-wishlist-button-color);
        //
        //    @include before-desktop {
        //        transform: scale(.6) rotate(-45deg);
        //    }
        //}

        //::before,
        //::after {
        //    background-color: var(--product-card-wishlist-button-color);
        //}

        &:hover {
            @include after-mobile {
                opacity: 1;
                transition: all .2s;
            }

            //.ProductWishlistButton-Heart {
            //    @include after-mobile {
            //        background-color: var(--primary-base-color);
            //    }
            //}

            //::before,
            //::after {
            //    @include after-mobile {
            //        background-color: var(--primary-base-color);
            //    }
            //}
        }

        //&:not([disabled]):hover,
        //&:not([disabled]):focus {
        //    background-color: var(--product-card-wishlist-button-background);
        //}
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    .ProductWishlistButton {
        //margin: 0 0 1.08rem 0;

        &-Button_isInWishlist {
            //background-color: var(--product-card-wishlist-button-background);
            //opacity: 1;

            .ProductWishlistButton-Heart {
                //background-color: var(--primary-base-color);
            }

            //::before,
            //::after {
            //    background-color: var(--primary-base-color);
            //}
        }
    }

    .ProductCompareButton {
        margin: 0 0 1.08rem 0;

        &-Button {
            width: 35px;
            height: 35px;
            border: none;
        }

        &-Icon {
            height: 23px;
            margin: auto;

            &::before {
                width: 5px;
                left: -5px;
            }

            &::after {
                width: 5px;
            }
        }
    }

    &_siblingsHaveBrands {
        .ProductCard-Brand {
            min-height: var(--product-card-brand-line-height);
        }
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: 40px;
        }
    }

    &_siblingsHaveConfigurableOptions {
        .ProductCard-ConfigurableOptions {
            min-height: 20px;
        }
    }

    &-RefContainer {
        z-index: 1;
        position: absolute;
        top: 16px;
        left: 16px;

        @include mobile {
            top: 4px;
            left: 12px;
        }
    }

    &-RefLabel {
        font-family: $futuraDemi;
        font-size: 15px;
        line-height: 19.5px;
        text-transform: uppercase;
        color: $black;

        @include mobile {
            font-size: 12px;
            line-height: 15.72px;
        }
    }

    &-RefValue {
        font-family: $futuraDemi;
        font-size: 15px;
        line-height: 19.5px;
        text-transform: uppercase;
        color: $black;

        @include mobile {
            font-size: 12px;
            line-height: 15.72px;
        }
    }

    &.WishlistItem-ProductCard {
        flex-direction: column;

        .productWishwlistWrapper {
            display: flex;
            align-items: flex-start;
        }


        .ProductCard-Figure {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .whishlistWrapper {

        }

        .ProductCard-FigureReview {
            width: 45%;
            height: auto;
        }

        .ProductCard-Content {
            width: 55%;
            padding: 0;
            margin: 0;
            padding-left: 5%;
            box-sizing: border-box;
        }

        .ProductCard-sku {
            font-size: 14px;
            font-family: $futuraDemi;
            margin-bottom: 7px;
        }

        .ProductCard-Wrapper {
            flex-direction: column;
        }

        .ProductCard-Name, .ProductCard-Link {
            margin-top: 7px;

            p {
                font-size: 20px;
                font-familly: $dinCond;
                letter-spacing: 0.29px;
                text-transform: uppercase;
                font-weight: normal !important;
                margin: 0;
            }
        }

        .ProductCard-Link {
            width: 100%;

            p {
                font-family: $dinCond;
                font-size: 20px;
                line-height: 1;
                font-weight: normal !important;
                width: 80%;
            }

            @include before-desktop {
                p {
                    font-family: $dinCond;
                    font-size: 24px;
                    line-height: 1;
                    font-weight: normal !important;
                    width: 100%;
                }
            }
        }

        .ProductPrice {
            font-size: 16px;
            font-family: $dinCond;
            letter-spacing: 0.23px;
            font-weight: normal !important;
            margin: 0;
            text-align: left;

            @include before-desktop {
                font-size: 20px;
                margin-top: 10px;
            }
        }

        .ProductCard-Diamonds {
          justify-content: flex-start;
        }

        .ProductCard-PriceWrapper {
            width: 100%;
            justify-content: flex-start !important;
            margin-top: 0.5rem;
        }

        .productWishwlistRemove {
            cursor: pointer;
            font-size: 14px;
            font-family: $futuraDemi;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            margin: 15px 0;

            @include desktop {
                margin: 10px 0 15px 0;
            }

            .icon-close {
                font-size: 16px;
                margin-right: 10px;
            }
        }

        .productWishwlistLink {
            background-color: $white;
            color: $black;
            font-family: $futuraDemi;
            font-size: 15px;
            text-transform: uppercase;
            padding: 5px 20px;
            border: none;
            width: 100%;
            letter-spacing: 0.75px;
            border: 2px solid $black;
            text-align: center;
            text-decoration: none;
            transition: background-color 0.3s ease, color 0.3s ease;

            &:hover {
                background-color: $black;
                color: $white;
            }
        }
    }

    &-Diamonds {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 5px 0;
        width: 100%;
        padding-right: 15px;

        @include mobile {
            padding-right: 12px;
        }

        .icon-diamant {
            font-size: 1.6rem;
            margin-left: .4rem;
            color: $black;

            @include mobile {
                font-size: 1.1rem;
            }
        }

        &_noir .icon-diamant {
            color: $black;
        }

        &_or .icon-diamant {
            color: #d29e4d;
        }
    }
}

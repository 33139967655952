/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Header-Wrapper_noMenu {
    .PushNotification {
        &.hidden {
            top: calc(var(--header-total-height) - 50px);
        }
    }
}
.PushNotification {
    width: 100%;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: var(--header-total-height);
    left: 0;
    z-index: 99;
    padding: 10px;
    border-bottom: 1px solid $greyE7;
    transition: all ease-out .4s;

    &.hidden {
        transform: translateY(-100%);
        pointer-events: none;
    }

    @include mobile {
        top: var(--header-total-height);
    }

    &-Container {
        width: 100%;
    }

    &-Wrapper {
        @include mobile {
            padding-right: 2.4rem;
            padding-left: 2.4rem;
        }
    }

    &-Title {
        text-align: center;
        width: 100%;
        font-family: $futuraLight;
        font-size: 1.4rem;
        font-weight: inherit;
        text-transform: uppercase;

        @include mobile {
            display: none;
        }
    }

    &-Content {
        text-align: center;
        font-family: $futuraBook;
        font-size: 1.2rem;
        font-weight: inherit;

        p:last-child {
            margin-bottom: 0;
        }
    }

    &-CloseBtn {
        @include close-button;

        height: 35px;
        width: 40px;
        position: absolute;
        top: 50%;
        right: 1rem;
        z-index: 5;
        transform: translateY(-50%);

        @include mobile {
            right: -1rem;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --footer-height: calc(var(--footer-content-height) + var(--footer-copyright-height));
    --footer-copyright-height: 50px;
}

.Footer {
    z-index: 2;

    @include mobile {
        margin-bottom: var(--navigation-tabs-height);
    }

    &-Content {
        background-color: $black;
    }

    &-Nav {
        display: flex;
        height: 100%;
        padding: 25px 40px;
        justify-content: center;

        @include mobile {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 40px;
        }

        @include after-mobile {
            padding: 60px 70px;
        }

        @include tablet {
            padding: {
                left: 30px;
                right: 30px;
            };
        }
    }

    &-NavItem {
        color: $white;
        font-size: 1.2rem;
        text-transform: uppercase;
        font-family: $futuraDemi;
        display: flex;
        align-items: center;
        margin-right: 8.5rem;
        text-decoration: none !important;

        .icon {
            font-size: 1.8rem;
            margin-right: 0.5rem;
            color: $white;
        }

        &:last-child {
            margin: 0;
        }

        &:hover {
            text-decoration: none;
        }

        @include mobile {
            margin-right: 0;
            margin-bottom: 2rem;
        }
    }

    &-NavItemIcon {
        max-width: 1.8rem;
        width: 100%;
        margin-right: 0.5rem;
    }
}

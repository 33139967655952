/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$select-width: 160px !default;
$select-height: 45px !default;
$select-option-height: 36px !default;
$select-arrow-height: 6px !default;
$select-arrow-width: 6px !default;

:root {
    --select-option-background: #fff;
    --select-option-hover-background: #f0f0f0;
}

.FieldSelect {
    cursor: pointer;
    @include desktop {
        transform: translateY(calc(50% - 22px));
    }

    &::after {
        // content: '';
        // display: block;
        // width: $select-arrow-width;
        // height: $select-arrow-height;
        // transform: translateY(-2px) rotate(45deg);
        // pointer-events: none;

        // position: absolute;
        // right: 20px;
        // bottom: 0;
        // top: 0;
        // margin: auto;

        // border-style: solid;
        // border-color: #000;
        // border-width: 0 1px 1px 0;
        content: '';
        position: absolute;
        right: 30px;
        top: 50%;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 5px solid black;
        transition: transform 200ms ease-in-out;
        transform: translateY(-50%) rotate(180deg);
        will-change: transform;
    }

    &_isExpanded::after {
        @include desktop {
            bottom: $select-arrow-height * 3/2;
            transform: translateY(-2px) rotate(-135deg);
        }
    }

    &-Select {
        width: 100%;
        min-width: 100%;
        padding-right: 40px;

        @include desktop {
            pointer-events: none;
        }

        &_isExpanded {
            border-color: var(--input-color);
        }
    }

    &-Options {
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        z-index: 40;
        border-width: 0 1px 1px;
        border-style: solid;
        border-color: transparent;

        overflow-y: scroll;
        transition: 200ms max-height;
        will-change: max-height;
        max-height: 0;
        -webkit-overflow-scrolling: touch;

        // Firefox support
        scrollbar-width: none !important;
        scrollbar-color: none !important;

        &::-webkit-scrollbar {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            display: none;
        }

        &_isExpanded {
            @include desktop {
                max-height: 200px;
                border-color: var(--input-border-color);
                overflow-y: auto;
            }
        }
    }

    &-Option {
        padding: 0;
        margin: 0;
        z-index: 1;
        transition: 100ms max-height ease-in;
        will-change: max-height;
        max-height: $select-option-height;
        line-height: 3.6rem;
        padding-left: 2rem;
        background: var(--select-option-background);
        opacity: 0.5;

        &.active {
            opacity: 1;
        }

        @include mobile {
            line-height: 4.2rem;
            padding-left: 2.1rem;
        }

        &::before {
            content: none;
        }

        &:last-of-type {
            margin: 0;
        }

        &:hover,
        &:focus {
            @include desktop {
                background: var(--select-option-hover-background);
            }
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --mobile-header-height: 70px;
}

.MobileMenu {
    z-index: 1000;

    &-Background, &-Pane {
        height: calc(100vh - var(--mobile-header-height));
        position: fixed;
        top: var(--mobile-header-height);
        left: 0;
        right: 0;
        bottom: 0;
    }

    &-Background {
        background: rgba($black, 0.7);
        visibility: hidden;
        opacity: 0;
    }

    &-Pane {
        background: $white;
        height: calc(100% - var(--mobile-header-height));
        width: calc(100vw - 2.4rem);
        padding: 2.5rem 2.4rem 0;
        transform: translateX(-100%);
        display: flex;
        flex-direction: column;
    }

    &-Categories {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex: 1;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    &-ItemList {
        height: 100%;
    }

    &-Item {
        padding: 0;
        margin: 0;

        &:before {
            display: none;
        }

        &:last-child {
            padding-bottom: 20px;
        }

        &.hideMobile {
          @include mobile {
            display: none;
          }
        }
    }

    &-Link {
        color: $black;
        font-family: $futuraBook;
        font-size: 1.8rem;
        text-transform: uppercase;
        padding: 1.5rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none !important;

        &_parentLink, &_allCollectionsLink, &_secondMenuItem {
            justify-content: flex-start;

            span {
                &:first-child {
                    margin-right: 1rem;
                }
            }
        }

        &_allCollectionsLink {
            text-transform: none;
            text-decoration: underline !important;
        }

        &_parentLink {
            font-family: $futuraDemi;
        }
    }

    &-Divider {
        height: 1px;
        width: 100%;
        background: $greyE7;
        margin: 1rem 0;
    }

    &-Submenu {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        padding: 4rem 2.4rem 0;
    }
}
